import { Button, colors, Csku, Popup, Theme, themeOptions, Typography } from "@commonsku/styles";
import ErrorBoundary from "../components/ErrorBoundary";
import MainSection from "./MainSection";
import React, { useState } from "react";
import { GlobalStyle } from "./DashboardApp";
import { useSelector } from "react-redux";
import AdminBillingRenewalDetails from "../components/admin-new/AdminBillingRenewalDetails";
import { useIdentity } from "../hooks";

const ContractChangesApp = () => {
  const identity = useIdentity();
  const lockedOut = useSelector(state => (state as any).entities.lockedOut);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  let title: string;
  let description: string;
  let button: string;
  let boxedContent: React.ReactNode;

  if (lockedOut) {
    title = 'Action Required: Complete Your Account Renewal to Continue';
    description = `Your commonsku account renewal is past due. To ensure uninterrupted access to the platform, please complete the renewal process now.\n\nTo proceed, please review your plan and payment preferences. Once completed, your account will be reactivated immediately.`;
    button = 'Complete Renewal';
    boxedContent = <>
      <Typography.H2>Why complete your account renewal</Typography.H2>
      <p><span style={{fontWeight: 'bold'}}>Stay Connected</span>: Completing your renewal ensures your teams uninterrupted access to commonsku's tools and features to support your business.</p>
      <p><span style={{fontWeight: 'bold'}}>Choose Your Plan</span>: Select the plan that best fits your business needs and aspirations - Essentials or Advanced.</p>
      <p><span style={{fontWeight: 'bold'}}>Secure Discounts</span>: Complete your renewal, and take advantage of saving 10% by paying annually for your licenses.</p>
    </>;
  } else {
    title = 'Upcoming Changes To Your commonsku Account';
    description = 'Starting November 1st, all customers will be required to move to annual contracts, and select a commonsku package. Selecting your preferred commonsku package ensures your business is equipped with the tools and features to best support your needs.';
    button = 'Continue';
    boxedContent = <>
      <Typography.H2>What you need to know</Typography.H2>
      <p><span style={{fontWeight: 'bold'}}>Renewal Date</span>: Your annual contract will automatically renew on your renewal date, which is the 1st of the month following your sign-up date. We'll notify you of your future renewal dates 60 days before the renewal of your contract.</p>
      <p><span style={{fontWeight: 'bold'}}>Flexible Plan Options</span>: Choose our Essentials or Advanced plan that best suit your needs.</p>
      <p><span style={{fontWeight: 'bold'}}>Annual Discount</span>: Save 10% by choosing to pay annually for your commonsku licenses. This discount applies to your first pro-rated invoice and all future annual payments.</p>
    </>;
  }

  const popup = !showPopup ? null :
    <Popup
      style={{
        padding: 20,
        width: 'calc(100vw - 100px)',
        maxWidth: '50rem',
        height: 'auto',
        maxHeight: 'initial'
      }}
      noHeader={true}
      header={null}
    >
      <AdminBillingRenewalDetails
        onClose={(thankYou: boolean) => {
          thankYou ? window.location.replace('/home.php') : setShowPopup(false)
        }}
        identity={identity}
      />
    </Popup>;

  return (
    <Theme theme={themeOptions}>
      <GlobalStyle />
      <ErrorBoundary>
        <MainSection style={{background: colors.neutrals[20], height: '100%'}} lockedOut={lockedOut}>
          {popup}
          <Csku as={"div"} className="main-content">
            <div style={{width: 770, margin: '60px auto'}}>
              <Typography.H1 style={{color: colors.neutrals[90]}}>{title}</Typography.H1>
              <p style={{fontSize: 18, whiteSpace: 'pre-line'}}>{description}</p>
              <Button
                style={{margin: '40px auto', display: 'block'}}
                size="huge"
                cta={true}
                onClick={() => setShowPopup(true)}
              >{button}</Button>
              <div style={{borderRadius: 10, background: 'white', padding: 30}}>
                {boxedContent}
              </div>
            </div>
          </Csku>
        </MainSection>
      </ErrorBoundary>
    </Theme>
  );

};

export default ContractChangesApp;
